<template>
  <div>
    <b-table stacked :items="revenueData"></b-table>
    <Survey
      v-if="reportRevenue"
      :user="reportRevenue.user['@id']"
      :year="reportRevenue.dateSubmitted.split('-')[0]"
      :month="reportRevenue.dateSubmitted.split('-')[1]"
      :surveyId="1"
      :readOnly="true"
    />
    <template v-if="isAdmin && revenueDefaults.defaults">
      <b-card class="mt-4">
        <h5 class="mb-3">Revenue Defaults <b-btn size="sm" variant="info" class="float-right" :to="{ name: 'invoiceList', query: {
              type: 'datePaid',
              mo: reportRevenue.reptMo,
              yr: reportRevenue.reptYr,
              bid: revenueBusinessId
            } }" target="_blank">View Invoices</b-btn></h5>
        <b-table stacked :items="[revenueDefaults.defaults]"></b-table>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { useSetRevenueDefaults } from "@/composables/useRevenueDefaults"
import Survey from '@/components/Survey'

export default {
  name: 'RevenueDetails',
  components: {
    Survey
  },
  props: {
    rid: {
      type: Number,
      default: null,
      required: false
    }
  },
  data () {
    return {
      revenueDefaults: {}
    }
  },
  computed: {
    ...mapGetters([
      'reportRevenue'
    ]),
    rptId () {
      return this.rid || this.$route.params.rptId
    },
    revenueBusinessId () {
      return this.reportRevenue.business?.split('/')[3]
    },
    revenueData () {
      let res = []
      if (!this.reportRevenue || !this.reportRevenue.data) {
        return res
      }
      let data = this.reportRevenue.data,
      obj = {}
      obj.dateSubmitted = this.$moment(this.reportRevenue.dateSubmitted).format('YYYY-MM-DD')
      obj.for = this.reportRevenue.reptYr + '/' + this.reportRevenue.reptMo
      obj.by = this.reportRevenue.user.fullName
      for (let i in data) {
        obj[data[i].label] = (data[i].type === 'money' ? '$' : '') + data[i].value
      }
      obj.totalRevenue = '$' + this.reportRevenue.totalRevenue
      obj.royaltiesDue = '$' + this.reportRevenue.royalties
      obj.comments = this.reportRevenue.comments || '- no comments -'
      res.push(obj)
      return res
    }
  },
  methods: {
    ...mapActions([
      'getReportsRevenue',
      'resetReport'
    ]),
    doGetReportsRevenue () {
      const query = {
        id: this.rptId,
        business: !this.isAdmin ? this.currentBusiness['@id'] : null
      }
      this.getReportsRevenue(query)
    }
  },
  watch: {
    rid: {
      handler: async function (val) {
        if (val) {
          await this.doGetReportsRevenue()
        } else {
          this.resetReport()
        }
      },
      immediate: true
    },
    reportRevenue: {
      handler: async function (val) {
        if (val) {
          this.revenueDefaults = await useSetRevenueDefaults(this.revenueBusinessId, this.$moment(`${this.reportRevenue.reptYr}-${this.reportRevenue.reptMo}-01`))
        } else {
          this.revenueDefaults = {}
        }
      },
      immediate: true
    }
  },
  async mounted () {
    await this.doGetReportsRevenue()
  }
}
</script>
